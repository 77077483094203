/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// UI lib components
import { Container } from 'react-grid-system';

// Page wrappers
import Layout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Local page components
// @TODO: Import local components here

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NotFoundPage() {
  return (
    <Layout>
      <Seo title="404: Not found" />
      <Container fluid>
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["Common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
